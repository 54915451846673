<template>
  <ModalDialog
    customClass="go-to-live-modal"
    :isShow="dialogOptions.isShow"
    :isCanClose="false"
    :size="655"
    :footerButtons="dialogOptions.footerButtons"
    :isClickOutside="false"
    @success="$emit('successClicked')"
    @close="$emit('closeClicked')"
  >
    <template v-slot:custom-header>
      <div class="go-to-live-modal__title-header">
        {{ dialogOptions.title }}
      </div>
    </template>
    <template v-slot:description>
      <div class="go-to-live-modal__subtitle">Unlock Full Access</div>
      <p class="go-to-live-modal__description">
        Congratulations, you've scheduled your first 6 posts! Ready for more?
        Subscribe to one of our plans and continue growing your social media
        account.
      </p>
    </template>
  </ModalDialog>
</template>

<script>
import ModalDialog from "@/components/global/ModalDialog.vue";

export default {
  name: "GoToLiveModal",
  components: { ModalDialog },
  props: {
    dialogOptions: {
      type: Object,
      default: () => {
        return {
          isShow: false,
          title: "Ready to Go Live?",
          footerButtons: [],
        };
      },
    },
  },
};
</script>

<style scoped lang="scss">
.go-to-live-modal {
  &__title-header {
    font-weight: 700;
    font-size: 40px;
    color: #1f2325;
    margin-bottom: 20px;
  }
  &__subtitle {
    text-align: left;
    font-size: 31px;
    font-weight: 700;
    color: #70c5b0;
    margin-bottom: 30px;
  }
  &__description {
    text-align: left;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.4em;
  }
}
</style>
