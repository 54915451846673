<template>
  <div class="smb-feedback">
    <Loader v-if="isLoading" />
    <SmbTitles
      :isShowTitle="true"
      :title="`Hi ${getCurrentSmbCustomer.personal_name}, you have content ready for your review`"
    />
    <SmbNotificationBar
      :is-show="
        isCanSmbCustomerGenerateStoriesInTestMode &&
        !isNeedToConnectSocialNetworks
      "
    >
      <template v-slot:notification_title>Summer special - Stories</template>
      <template v-slot:notification_text>
        <strong> Starter users get Pro perks for free! </strong> Now, for a
        limited time, you too can <strong>generate Stories</strong> alongside
        your Posts. Try it out now!
      </template>
    </SmbNotificationBar>
    <SmbNotificationBar :is-show="getCurrentSmbCustomer.plan !== 1">
      <template v-slot:notification_text>
        <div class="reels-introducing">
          <strong class="reels-introducing__title">
            Introducing Videos (Reels) - New feature!
          </strong>
          <br />
          <p class="reels-introducing__description">
            From now on, get smart, engaging Reels in your suggested content,
            and effortlessly convert static posts into videos (reels) by
            <strong
              >hovering over the image and clicking Change to video!</strong
            >
          </p>
        </div>
      </template>
    </SmbNotificationBar>
    <SmbRequiredSteps />
    <template>
      <div class="sort-by-container" v-if="posts.length || stories.length">
        <span class="sort-by-container__label">Sort by:</span>
        <v-select
          class="sort-by-container__select"
          :items="sortByItems"
          v-model="sortByValue"
          item-text="text"
          item-value="id"
        ></v-select>
      </div>
      <div class="smb-feedback__wrapper">
        <div class="suggest-content-container">
          <v-tooltip :z-index="900" top>
            <template v-slot:activator="{ on, attrs }">
              <div class="suggest-post-container" v-bind="attrs" v-on="on">
                <div @click="suggestPostHandler">
                  <div class="suggest-img-container">
                    <img
                      class="suggest-post-container__img"
                      src="@/assets/img/SaasGeneratePost.png"
                      alt=""
                    />
                    <img
                      class="suggest-post-container__img-hover"
                      src="@/assets/img/SaasGeneratePostHover.png"
                      alt=""
                    />
                    <Loader
                      v-if="isFetchingSuggestedPost"
                      z-index10-custom-class
                      class="loader"
                    />
                  </div>
                  <div class="suggest-post-container__text">
                    Suggest new post
                  </div>
                </div>
              </div>
            </template>
            <span>Click here to generate a new AI suggested Post.</span>
          </v-tooltip>
          <v-tooltip v-if="getCurrentSmbCustomer.plan !== 1" :z-index="900" top>
            <template v-slot:activator="{ on, attrs }">
              <div class="suggest-story-container" v-bind="attrs" v-on="on">
                <div @click="suggestStoryHandler">
                  <div class="suggest-img-container">
                    <img
                      class="suggest-story-container__img"
                      src="@/assets/img/SaasGenerateStory.png"
                      alt=""
                    />
                    <img
                      class="suggest-story-container__img-hover"
                      src="@/assets/img/SaasGenerateStoryHover.png"
                      alt=""
                    />
                    <Loader
                      v-if="isFetchingSuggestedStory"
                      z-index10-custom-class
                      class="loader"
                    />
                  </div>
                  <div class="suggest-story-container__text">
                    Suggest new story
                  </div>
                </div>
              </div>
            </template>
            <span>Click here to generate a new AI suggested Story.</span>
          </v-tooltip>
        </div>
        <div
          v-if="!posts.length && !stories.length && !isLoading"
          class="smb-feedback__empty"
        >
          <div class="smb-feedback__empty-icon">
            <img src="@/assets/img/icons/success-smb-icon.svg" />
          </div>
          <div class="smb-feedback__empty-title">You’re all done!</div>
          <div class="smb-feedback__empty-description">
            <p>
              Your posts will be published according to the approved schedule.
            </p>
            <p>
              We will notify you once we have new recommended content :D In the
              meantime, you can create any content you like in the Generate
              content page or from the AI suggestion buttons here.
            </p>
          </div>
        </div>
        <PostList
          v-else
          :dividerIsShow="false"
          :posts="isSuggestedPostWasCreatedOnce ? posts : sortedPosts"
          :stories="isSuggestedStoryWasCreatedOnce ? stories : sortedStories"
          :postType="'smb-dashboard'"
          :isFacebookPlatform="false"
          :currentCustomer="getCurrentSmbCustomer"
          :isShowSwitch="false"
          :isShowRating="false"
          isHeaderClient
          isShowSubmitBtn
          isCanAddACoupon
          isShowEditIconForClient
          isShowIndication
          isShowSmbAction
          isSmbPendingPage
          isShowStoryTitle
          :publishDateFormat="publishDateFormat"
          @focusOnInput="focusOnInputHandler"
          @blurFromInput="blurInputHandler"
          @updateRejectedList="setToRejectedList"
          @deleteFromRejectedContent="deleteFromRejectedContentHandler"
          @updatedCoupon="updatedCouponHandler"
          @changeDataInPost="changeData()"
          @changeDataInStory="changeData()"
          @updateSchedule="updateScheduleHandler($event)"
          @cancelScheduling="cancelSchedulingHandler($event)"
          @rejectSmbPost="
            actionsSmbPostHandler({ item: $event, actionType: 'reject' })
          "
          @saveSmbPost="
            actionsSmbPostHandler({ item: $event, actionType: 'save' })
          "
          @publishSmbPostNow="
            actionsSmbPostHandler({ item: $event, actionType: 'now' })
          "
          @scheduleSmbPost="
            actionsSmbPostHandler({ item: $event, actionType: 'schedule' })
          "
          @updateCaptionValues="updateCaptionValuesHandler($event)"
          @editTemplateImage="editTemplateImageHandler($event)"
          @showMore="showMoreHandler($event)"
          @showedEditTemplateDialog="trackLogrocketAction('editMedia')"
          @editCaption="trackLogrocketAction('editCaption')"
          @approvedToAnimatePost="animatePostHandler($event)"
          @revertAnimatedPost="revertAnimateHandler($event)"
          @changeSocialAccounts="changeSocialAccountsHandler($event)"
        />
      </div>
    </template>
    <SmbUndoAction
      @closeActionNotification="closeActionNotification()"
      @undoAction="backItem()"
      :isShow="undoActionIsShow"
      :actionType="activeAction && activeAction.type ? activeAction.type : ''"
    />
    <div class="smb-feedback__footer" v-show="isShowBackBtn">
      <SmbActionBtn text="Back" @click="$router.push({ name: 'smb-client' })" />
    </div>
    <Referrals
      v-show="isShowReferrals"
      id="referralContainer"
      :code="getCurrentSmbCustomer.stripe_coupon"
      :customer-name="
        getCurrentSmbCustomer.personal_name +
        ' ' +
        (getCurrentSmbCustomer.last_name || getCurrentSmbCustomer.name)
      "
      :referrerEmail="getCurrentSmbCustomer.mail"
      :customerPersonalName="getCurrentSmbCustomer.personal_name"
    />
    <GoToLiveModal
      :dialog-options="dialog"
      @successClicked="redirectCustomerToTheWebsitePaymentPage"
      @closeClicked="dialog.isShow = false"
    />
    <ModalDialog
      :isShow="changeSocialAccounts.isShow"
      customClass="change-social-media-modal"
      :title="changeSocialAccounts.title"
      :subTitle="changeSocialAccounts.subTitle"
      :footerButtons="changeSocialAccounts.footerButtons"
      @close="changeSocialAccounts.isShow = false"
      @saveSocialPlatforms="saveSocialPlatformsHandler"
    >
      <template v-slot:description>
        <div class="social-networks">
          <div class="social-networks__list">
            <div
              v-for="(
                socialPlatform, index
              ) in getCurrentSmbCustomer.social_platforms"
              :key="socialPlatform"
              class="social-networks__list-item custom-checkbox"
            >
              <input
                :value="socialPlatforms.includes(socialPlatform)"
                :checked="socialPlatforms.includes(socialPlatform)"
                :disabled="
                  socialPlatforms.length === 1 &&
                  socialPlatforms.includes(socialPlatform)
                "
                class="styled-checkbox"
                :id="index"
                type="checkbox"
                @change="changeSocialPlatformHandler(socialPlatform)"
              />
              <label :for="index">
                <div class="icon">
                  <img
                    :src="
                      require(`@/assets/img/icons/${socialPlatform}_logo_rounded.svg`)
                    "
                    alt=""
                  />
                </div>
              </label>
            </div>
          </div>
        </div>
      </template>
    </ModalDialog>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";

import SmbTitles from "@/components/smb-client/SmbTitles";
import PostList from "@/components/createPost/PostList";
import Loader from "@/components/global/Loader";
import SmbUndoAction from "@/components/smb-client/SmbUndoAction";
import SmbActionBtn from "@/components/smb-client/SmbActionBtn";
import SmbRequiredSteps from "@/components/smb-client/SmbRequiredSteps.vue";
import SmbNotificationBar from "@/components/smb-client/SmbNotificationBar.vue";
import smbMethods from "@/mixins/smbMethods";
import LogRocket from "logrocket";
import Post from "@/models/Post";
import PostTooltip from "@/models/Post/PostTooltip";
import H from "@/utils/helper";
import Referrals from "@/components/global/Referrals.vue";
import GoToLiveModal from "@/components/smb-client/GoToLiveModal.vue";
import ModalDialog from "@/components/global/ModalDialog.vue";

export default {
  name: "smbFeedback",
  components: {
    ModalDialog,
    GoToLiveModal,
    Referrals,
    SmbNotificationBar,
    SmbRequiredSteps,
    SmbTitles,
    Loader,
    PostList,
    SmbUndoAction,
    SmbActionBtn,
  },
  mixins: [smbMethods],
  data: () => ({
    posts: [],
    stories: [],
    suggestedPosts: [],
    generatedPosts: [],
    suggestedStories: [],
    generatedStories: [],
    scheduledContent: [],
    isLoading: false,
    publishDateFormat: "MM/DD/YYYY",
    rejectedContent: [],
    isFocusOnCommentInput: false,
    isBoosterCountDown: false,
    polling: null,
    currentPost: null,
    undoActionIsShow: false,
    activeAction: null,
    initialPostData: null,
    timer: null,
    sortByItems: [
      {
        id: 1,
        text: "Suggested content",
      },
      {
        id: 2,
        text: "Generated by you",
      },
    ],
    sortByValue: 1,
    prevRoute: null,
    suggestedPostsOrStoriesIdsThatLoadedWithSkeleton: [],
    isFetchingSuggestedPost: false,
    isFetchingSuggestedStory: false,
    defaultTooltip: {
      color: null,
      hint: "Generating content...",
      textColor: "",
      tip: "",
      type: 4,
    },
    isHoverOnSuggestPost: false,
    isHoverOnSuggestStory: false,
    isSuggestedPostWasCreatedOnce: false,
    isSuggestedStoryWasCreatedOnce: false,
    postsLimits: {
      suggested: 6,
      generated: 10,
    },
    storiesLimits: {
      suggested: 6,
      generated: 10,
    },
    loadMoreStep: {
      posts: 10,
      stories: 10,
    },
    isShowReferrals: false,
    isShowBackBtn: false,
    dialog: {
      isShow: false,
      title: "Ready to Go Live?",
      footerButtons: [
        {
          name: "Choose package",
          emitName: "success",
          btnClass: "blue-border",
        },
        { name: "Keep exploring", emitName: "close", btnClass: "black-border" },
      ],
    },
    changeSocialAccounts: {
      isShow: false,
      title: "Change social media accounts",
      subTitle: "Please note that the changes here are just for this content.",
      footerButtons: [
        {
          name: "Save",
          emitName: "saveSocialPlatforms",
          btnClass: "blue-border",
        },
      ],
    },
    socialPlatforms: [],
  }),
  computed: {
    ...mapGetters("smbClientSelection", ["getCurrentSmbCustomer"]),
    ...mapGetters("smbPost", ["getAmountPostsToReview", "getRefreshPostList"]),
    isFacebookPlatform() {
      return (
        this.getCurrentSmbCustomer.social_platforms.length === 1 &&
        this.getCurrentSmbCustomer.social_platforms.includes("facebook")
      );
    },
    sortedPosts() {
      return this.sortPendingList(this.posts);
    },
    sortedStories() {
      return this.sortPendingList(this.stories);
    },
    isShowSurvey() {
      let filterScheduledContent = this.scheduledContent.filter(
        (item) =>
          item.publishState === "scheduled" || item.publishState === "manual"
      );
      const diffCreatedAtDate = this.$dayjs(new Date()).diff(
        this.$dayjs.unix(this.getCurrentSmbCustomer.createdAt),
        "day"
      );
      const lastMonthItemsList = filterScheduledContent.filter(
        (i) =>
          this.$dayjs(new Date()).diff(
            this.$dayjs.unix(i.scheduledFor),
            "day"
          ) <= 30
      );
      return diffCreatedAtDate > 1 && lastMonthItemsList.length >= 2;
    },
  },
  watch: {
    getRefreshPostList: function (val) {
      if (val) {
        this.isLoading = true;
        //this.getData();
        this.isLoading = false;
      }
      // this.setRefreshPostList(false);
    },
    sortByValue: function (val) {
      // this.isSuggestedPostWasCreatedOnce = false;
      // this.isSuggestedStoryWasCreatedOnce = false;
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.prevRoute = from && from.name ? from.name : null;
    });
  },
  async created() {
    this.isLoading = true;
    await this.getData();
    this.isShowReferrals = true;
    this.isShowBackBtn = true;
    this.isLoading = false;
    await this.getScheduledContents();
  },
  async mounted() {
    if (this.prevRoute === "generate-content") {
      this.sortByValue = 2;
    }
  },
  methods: {
    ...mapActions("smbPost", [
      "fetchSmbData",
      "actionSmbPosts",
      "fetchAmountsPostsToReview",
    ]),
    ...mapActions("clientDashboard", ["updatePosts"]),
    ...mapActions("recommendations", ["createPostFromRecommendation"]),
    ...mapMutations("smbPost", [
      "setAmountPostsToReview",
      "setRefreshPostList",
    ]),
    ...mapActions("calendar", ["getPost"]),
    ...mapActions("templates", ["checkVideoIsUpload"]),
    ...mapActions("templates", ["getTemplatesInfo", "createImageFromTemplate"]),
    ...mapActions("mediaLibraryWidget", ["uploadMediaFromUrl"]),
    ...mapActions("smbClientSelection", ["fetchSmbClientInfo"]),
    ...mapActions("globals", ["saveTrialUserInfo"]),

    amountPosts() {
      this.setAmountPostsToReview(this.posts.length + this.stories.length);
    },

    editTemplateImageHandler(dataPost) {
      let searchPost =
        this.posts.find((post) => post.id === dataPost.id) ||
        this.stories.find((post) => post.id === dataPost.id);
      if (searchPost) {
        searchPost.media = [];
        setTimeout(() => {
          searchPost.media = dataPost.media;
        }, 1);
      }
    },

    async getScheduledContents() {
      try {
        this.scheduledContent = await this.fetchSmbData({
          routeName: "archive",
          customerId: this.getCurrentSmbCustomer.id,
          limit: 2,
        });
      } catch (e) {
        console.log(e);
      }
    },

    async getData() {
      try {
        await this.fetchPosts();
        await this.fetchStories();
        this.isLoading = false;
      } catch (e) {
        this.isLoading = false;
        console.log(e);
      }
    },
    async showMoreHandler({ type, name }) {
      [...this.posts, ...this.stories].forEach((post) => {
        if (post.type === type && post.name === name) {
          post.isLoading = !post.isLoading;
        }
      });

      switch (name) {
        case "suggestedPosts":
          await this.fetchSuggestedPosts();
          break;
        case "generatedPosts":
          await this.fetchGeneratedPosts();
          break;
        case "suggestedStories":
          await this.fetchSuggestedStories();
          break;
        case "generatedStories":
          await this.fetchGeneratedStories();
          break;
        default:
          break;
      }
    },
    async fetchPosts() {
      try {
        await this.fetchSuggestedPosts();
        await this.fetchGeneratedPosts();
        this.posts = [...this.suggestedPosts, ...this.generatedPosts];
        this.posts.map((i) => {
          if (
            this.$dayjs(new Date()).diff(
              this.$dayjs.unix(i.createAt),
              "minute"
            ) < 1
          ) {
            i.isSkeletonLoading = true;
            i.isLoadingTime = 0;
            i.intervalId = setInterval(() => {
              i.isLoadingTime++;
              this.getSuggestedPosts(i.id, i.isLoadingTime > 60 ? true : false);
            }, 1000);
          }
          return i;
        });
      } catch (e) {
        console.error(e);
      }
    },
    async fetchStories() {
      try {
        await this.fetchSuggestedStories();
        await this.fetchGeneratedStories();
        this.stories = [...this.suggestedStories, ...this.generatedStories];
        this.stories.map((i) => {
          if (
            this.$dayjs(new Date()).diff(
              this.$dayjs.unix(i.createAt),
              "minute"
            ) < 1
          ) {
            i.isSkeletonLoading = true;
            i.isLoadingTime = 0;
            i.intervalId = setInterval(() => {
              this.getSuggestedStories(
                i.id,
                i.isLoadingTime > 60 ? true : false
              );
              i.isLoadingTime++;
            }, 1000);
          }
          return i;
        });
      } catch (e) {
        console.error(e);
      }
    },
    async fetchSuggestedPosts() {
      const { suggested: suggestedLimit } = this.postsLimits;
      const { posts: postPaginationStep } = this.loadMoreStep;

      const suggestedPostsResponse = await this.fetchData(
        suggestedLimit + 1,
        false
      );

      const suggestedContentWithSkeleton = this.posts.filter(
        (i) => i.isSkeletonLoading
      );

      this.suggestedPosts = suggestedPostsResponse.filter(
        (item, index) => index < suggestedLimit
      );

      if (suggestedPostsResponse.length > suggestedLimit) {
        this.suggestedPosts.push({
          id: Math.random(),
          type: "paginationButton",
          name: "suggestedPosts",
          text: "Load More Suggested Posts",
          isLoading: false,
          isDisabled: false,
        });
      }

      this.posts = [
        ...suggestedContentWithSkeleton,
        ...this.suggestedPosts,
        ...this.generatedPosts,
      ];

      this.postsLimits.suggested += postPaginationStep;
    },
    async fetchGeneratedPosts() {
      const { generated: generatedLimit } = this.postsLimits;
      const { posts: postPaginationStep } = this.loadMoreStep;

      const generatedPostsResponse = await this.fetchData(
        generatedLimit + 1,
        true
      );

      this.generatedPosts = generatedPostsResponse.filter(
        (item, index) => index < generatedLimit
      );

      if (generatedPostsResponse.length > generatedLimit) {
        this.generatedPosts.push({
          id: Math.random(),
          type: "paginationButton",
          name: "generatedPosts",
          text: "Load More Generated Posts",
          isLoading: false,
          isDisabled: false,
        });
      }

      this.posts = [...this.suggestedPosts, ...this.generatedPosts];
      this.postsLimits.generated += postPaginationStep;
    },
    async fetchSuggestedStories() {
      const { suggested: suggestedLimit } = this.storiesLimits;
      const { stories: storiesPaginationStep } = this.loadMoreStep;

      const suggestedStoriesResponse = await this.fetchData(
        suggestedLimit + 1,
        false,
        "story"
      );

      this.suggestedStories = suggestedStoriesResponse.filter(
        (item, index) => index < suggestedLimit
      );

      if (suggestedStoriesResponse.length > suggestedLimit) {
        this.suggestedStories.push({
          id: Math.random(),
          type: "paginationButton",
          name: "suggestedStories",
          text: "Load More Suggested Stories",
          isLoading: false,
          isDisabled: false,
        });
      }

      this.stories = [...this.suggestedStories, ...this.generatedStories];
      this.storiesLimits.suggested += storiesPaginationStep;
    },
    async fetchGeneratedStories() {
      const { generated: generatedLimit } = this.storiesLimits;
      const { stories: storiesPaginationStep } = this.loadMoreStep;

      const generatedStoriesResponse = await this.fetchData(
        generatedLimit + 1,
        true,
        "story"
      );

      this.generatedStories = generatedStoriesResponse.filter(
        (item, index) => index < generatedLimit
      );

      if (generatedStoriesResponse.length > generatedLimit) {
        this.generatedStories.push({
          id: Math.random(),
          type: "paginationButton",
          name: "generatedStories",
          text: "Load More Generated Stories",
          isLoading: false,
          isDisabled: false,
        });
      }

      this.stories = [...this.suggestedStories, ...this.generatedStories];
      this.storiesLimits.generated += storiesPaginationStep;
    },
    async fetchData(limit = 10, smb = true, type = "post") {
      try {
        return await this.fetchSmbData({
          routeName: "pre-approved",
          customerId: this.getCurrentSmbCustomer.id,
          limit: limit,
          smb: smb,
          type: type,
        });
      } catch (e) {
        this.isLoading = false;
        console.log(e);
      }
    },

    async cancelSchedulingHandler(data) {
      try {
        const params = {
          contentCaptionId: data.contentCaptionId,
          customerApprove: "none",
          scheduledForStr: "",
          id: data.id,
        };
        await this.actionWithPost(params);
        data.scheduledFor = "";
        data.scheduledForStr = "";
        if (data.type === "post") {
          this.posts = this.posts.filter((i) => i.id !== data.id);
          this.posts.push(data);
        } else {
          this.stories = this.stories.filter((i) => i.id !== data.id);
          this.stories.push(data);
        }
      } catch (e) {
        console.log(e);
      }
    },

    async closeActionNotification() {
      clearTimeout(this.timer);
      this.initialPostData = null;
      this.undoActionIsShow = false;
      this.activeAction = null;
      this.currentPost = null;
    },

    startActionTimout() {
      [...this.posts, ...this.stories].forEach((contentItem) => {
        if (contentItem.type === "paginationButton") {
          contentItem.isDisabled = true;
        }
      });

      this.changeLimits(this.currentPost);

      this.timer = setTimeout(() => {
        this.activeAction = null;

        [...this.posts, ...this.stories].forEach((contentItem) => {
          if (contentItem.type === "paginationButton") {
            contentItem.isDisabled = false;
          }
        });

        this.undoActionIsShow = false;
        this.currentPost = null;
      }, 5000);
    },

    delightedSurvey() {
      window.delighted.survey({
        email: this.getCurrentSmbCustomer.mail,
        name: this.getCurrentSmbCustomer.name,
      });
    },

    async startAction(data) {
      switch (data.actionType) {
        case "reject":
          await this.rejectPost(data.item);
          await this.amountPosts();
          break;
        case "save":
          await this.saveSmbPostHandler(data.item);
          await this.amountPosts();
          break;
        case "now":
          await this.publishSmbPostNowHandler(data.item);
          await this.amountPosts();
          if (this.isShowSurvey) {
            this.delightedSurvey();
          }
          break;
        case "schedule":
          await this.scheduleSmbPostHandler(data.item);
          await this.amountPosts();
          if (this.isShowSurvey) {
            this.delightedSurvey();
          }
          break;
        default:
          console.log("Error");
      }
    },
    changeLimits(item, isIncrement = false) {
      if (this.suggestedPosts.includes(item)) {
        this.postsLimits.suggested = isIncrement
          ? this.postsLimits.suggested + 1
          : this.postsLimits.suggested - 1;
      }
      if (this.generatedPosts.includes(item)) {
        this.postsLimits.generated = isIncrement
          ? this.postsLimits.generated + 1
          : this.postsLimits.generated - 1;
      }
      if (this.suggestedStories.includes(item)) {
        this.storiesLimits.suggested = isIncrement
          ? this.storiesLimits.suggested + 1
          : this.storiesLimits.suggested - 1;
      }
      if (this.generatedStories.includes(item)) {
        this.storiesLimits.generated = isIncrement
          ? this.storiesLimits.generated + 1
          : this.storiesLimits.generated - 1;
      }
    },
    setInitialPostData(data) {
      let searchPost =
        this.posts.find((post) => post.id === data.item.id) ||
        this.stories.find((post) => post.id === data.item.id);
      this.initialPostData = {
        item: searchPost,
        type: data.actionType,
      };
    },

    async actionsSmbPostHandler(data) {
      if (this.getCurrentSmbCustomer.activity_state.toLowerCase() === "trial") {
        await this.fetchSmbClientInfo(this.getCurrentSmbCustomer.id);
      }

      if (
        (data.actionType === "now" || data.actionType === "schedule") &&
        !this.getCurrentSmbCustomer.allow_publish
      ) {
        this.dialog.isShow = true;
        await this.saveTrialUserInfo({
          id: this.getCurrentSmbCustomer.id,
          name: this.getCurrentSmbCustomer.personal_name,
          last_name: this.getCurrentSmbCustomer.last_name,
          email: this.getCurrentSmbCustomer.mail,
          phone: this.getCurrentSmbCustomer.primary_phone,
        });
        LogRocket.track("pending_approval_upgrade_popup");
      } else {
        this.trackLogrocketAction(data.actionType);
        clearTimeout(this.timer);
        this.initialPostData = null;
        this.setInitialPostData(data);
        this.activeAction = {
          item: data.item,
          type: data.actionType,
        };
        this.startAction(data);
        this.undoActionIsShow = true;
        this.hideItem(data.item);
        this.startActionTimout();
      }
    },
    trackLogrocketAction(actionType) {
      switch (actionType) {
        case "reject":
          LogRocket.track("pending_approval_reject");
          break;
        case "save":
          LogRocket.track("pending_approval_save_for_later");
          break;
        case "now":
          LogRocket.track("pending_approval_publish_now");
          break;
        case "schedule":
          LogRocket.track("pending_approval_schedule");
          break;
        case "editMedia":
          LogRocket.track("edit_media");
          break;
        case "editCaption":
          LogRocket.track("edit_caption");
          break;
        default:
          console.log("Error");
      }
    },
    hideItem(item) {
      if (item.type === "post") {
        let indexElement = this.posts.findIndex((i) => i.id === item.id);
        if (indexElement !== -1) {
          this.activeAction.indexInArray = indexElement;
          if (this.activeAction.type !== "save") {
            this.posts = this.posts.filter((i) => i.id !== item.id);
          } else {
            this.posts = this.posts.filter((i) => i.id !== item.id);
            this.posts.push(item);
          }
        }
      } else {
        let indexElement = this.stories.findIndex((i) => i.id === item.id);
        if (indexElement !== -1) {
          this.activeAction.indexInArray = indexElement;
          if (this.activeAction.type !== "save") {
            this.stories = this.stories.filter((i) => i.id !== item.id);
          } else {
            this.stories = this.stories.filter((i) => i.id !== item.id);
            this.stories.push(item);
          }
        }
      }
    },
    async backItem() {
      if (this.activeAction.item.type === "post") {
        if (this.activeAction.type !== "save") {
          this.posts.splice(
            this.activeAction.indexInArray,
            0,
            this.initialPostData.item
          );
        } else {
          this.posts.pop();
          this.posts.splice(
            this.activeAction.indexInArray,
            0,
            this.initialPostData.item
          );
        }
      } else {
        if (this.activeAction.type !== "save") {
          this.stories.splice(
            this.activeAction.indexInArray,
            0,
            this.activeAction.item
          );
        } else {
          this.stories.pop();
          this.stories.splice(
            this.activeAction.indexInArray,
            0,
            this.initialPostData.item
          );
        }
      }
      this.changeLimits(this.currentPost, true);
      clearTimeout(this.timer);
      this.undoActionIsShow = false;
      this.activeAction = null;

      [...this.posts, ...this.stories].forEach((contentItem) => {
        if (contentItem.type === "paginationButton") {
          contentItem.isDisabled = false;
        }
      });

      await this.undoPost();
    },
    async saveSmbPostHandler(data) {
      try {
        this.currentPost = data;
        const params = {
          contentCaptionId: this.currentPost.contentCaptionId,
          scheduledForStr: "",
          id: this.currentPost.id,
        };
        this.updateScheduleHandler({
          postId: this.currentPost.id,
          scheduledForStr: "",
        });
        await this.actionWithPost(params);
      } catch (e) {
        console.log(e);
      }
    },
    async publishSmbPostNowHandler(data) {
      try {
        this.currentPost = data;
        const params = {
          contentCaptionId: this.currentPost.contentCaptionId,
          customerApprove: "rush",
          id: this.currentPost.id,
          text: this.currentPost.text,
          byCustomer: true,
        };
        await this.actionWithPost(params);
      } catch (e) {
        console.log(e);
      }
    },
    async scheduleSmbPostHandler(data) {
      try {
        this.currentPost = data;
        const params = {
          contentCaptionId: this.currentPost.contentCaptionId,
          customerApprove: "published",
          id: this.currentPost.id,
          text: this.currentPost.text,
          byCustomer: true,
        };
        await this.actionWithPost(params);
      } catch (e) {
        console.log(e);
      }
    },
    async rejectPost(data) {
      try {
        this.currentPost = data;
        const params = {
          contentCaptionId: this.currentPost.contentCaptionId,
          customerApprove: "false",
          id: this.currentPost.id,
        };
        await this.actionWithPost(params);
      } catch (e) {
        console.log(e);
      }
    },
    async undoPost() {
      try {
        const params = {
          id: this.initialPostData.item.id,
          customerApprove: "none",
          scheduledForStr: this.initialPostData.item.scheduledFor
            ? this.initialPostData.item.scheduledForStr
            : "",
          scheduledFor: this.initialPostData.item.scheduledFor,
        };
        await this.actionWithPost(params);
        this.initialPostData = null;
      } catch (e) {
        console.log(e);
      }
    },
    async actionWithPost(params) {
      try {
        await this.actionSmbPosts(params);
      } catch (e) {
        console.log(e);
      }
    },

    updateScheduleHandler(postData) {
      let searchPost =
        this.posts.find((post) => post.id === postData.postId) ||
        this.stories.find((post) => post.id === postData.postId);
      searchPost
        ? (searchPost.scheduledForStr = postData.scheduledForStr)
        : null;
    },

    focusOnInputHandler(post) {
      if (
        this.rejectedContent &&
        this.rejectedContent.length &&
        this.rejectedContent[0].id === post.id
      ) {
        this.isFocusOnCommentInput = true;
      }
    },
    blurInputHandler() {
      this.isFocusOnCommentInput = false;

      if (
        this.rejectedContent.length &&
        this.rejectedContent[0].customerComment.trim().length > 2
      ) {
        this.deleteFromRejectedContentHandler(this.rejectedContent[0]);
      }
      if (this.rejectedContent.length) {
        this.countdownForFirstContentItem();
      }
    },
    setToRejectedList(contentObject) {
      this.isBoosterCountdown = false;
      let findInState = this.rejectedContent.find(
        (element) => element.id === contentObject.id
      );

      if (findInState) {
        contentObject.countdown = findInState.countdown;
        this.rejectedContent = this.rejectedContent.filter(
          (val) => val.id !== contentObject.id
        );
      } else {
        contentObject.countdown = 3;
      }

      this.rejectedContent.push(contentObject);

      this.rejectedContent = this.rejectedContent.filter(
        (val) => val.customerComment.trim().length < 2
      );

      this.countdownForFirstContentItem();
    },
    deleteFromRejectedContentHandler(contentObject) {
      this.rejectedContent = this.rejectedContent.filter(
        (val) => val.id !== contentObject.id
      );
    },
    updatedCouponHandler(postOrStory) {
      const index = this.posts.findIndex((item) => item.id === postOrStory.id);

      this.posts.splice(index, 1, postOrStory);
    },
    updateCaptionValuesHandler(params) {
      const foundIndex = this.posts.findIndex(
        (post) => post.id === params.postId
      );

      this.posts.splice(foundIndex, 1, {
        ...this.posts[foundIndex],
        ...params.newValues,
      });
    },
    async pollData() {
      clearTimeout(this.polling);
      this.polling = setTimeout(async () => {
        this.posts.map(async (post) => {
          await this.updatePosts({
            id: post.id,
            customerComment: post.customerComment,
            customerRating: post.customerRating,
          });
        });
      }, 2000);
    },
    async changeData() {
      await this.pollData();
    },
    sortPendingList(arrayForSort) {
      const suggestedContent = this.sortSuggested(arrayForSort);
      const generatedContent = this.sortGenerated(arrayForSort);

      const itemsWithoutTooltips = arrayForSort.filter(
        (itemFromArrayForSort) =>
          itemFromArrayForSort.tooltip &&
          itemFromArrayForSort.tooltip.length === 0 &&
          !itemFromArrayForSort.isSkeletonLoading
      );

      if (this.sortByValue === 2) {
        return [
          ...generatedContent,
          ...suggestedContent,
          ...itemsWithoutTooltips,
        ];
      } else {
        return [
          ...suggestedContent,
          ...generatedContent,
          ...itemsWithoutTooltips,
        ];
      }
    },
    sortSuggested(contentList) {
      const itemsWithTooltips = contentList.filter(
        (itemFromArrayForSort) =>
          itemFromArrayForSort.tooltip &&
          itemFromArrayForSort.tooltip.length > 0
      );

      const suggestedContentWithSkeleton = contentList.filter(
        (i) => i.isSkeletonLoading
      );

      const suggestedContent = itemsWithTooltips.filter(
        (i) => i.tooltip[0].type === 4
      );

      const suggestedContentWithScheduleDate = itemsWithTooltips
        .filter((i) => i.tooltip[0].type === 4 && i.scheduledFor !== "")
        .sort((a, b) => a.scheduledFor - b.scheduledFor);

      const suggestedContentWithoutScheduleDate = itemsWithTooltips
        .filter((i) => i.tooltip[0].type === 4 && i.scheduledFor === "")
        .sort((a, b) => a.scheduledFor - b.scheduledFor);

      const suggestedPaginationBtn = contentList.filter(
        (listItem) =>
          listItem.type === "paginationButton" &&
          (listItem.name === "suggestedPosts" ||
            listItem.name === "suggestedStories")
      );

      // return [
      //   ...suggestedContentWithSkeleton,
      //   ...suggestedContentWithScheduleDate,
      //   ...suggestedContentWithoutScheduleDate,
      //   ...suggestedPaginationBtn,
      // ];
      return [...suggestedContent, ...suggestedPaginationBtn];
    },
    sortGenerated(contentList) {
      const itemsWithTooltips = contentList.filter(
        (itemFromArrayForSort) =>
          itemFromArrayForSort.tooltip &&
          itemFromArrayForSort.tooltip.length > 0
      );

      const generatedByYou = itemsWithTooltips.filter(
        (i) => i.tooltip[0].type !== 4
      );

      const generatedPaginationBtn = contentList.filter(
        (listItem) =>
          listItem.type === "paginationButton" &&
          (listItem.name === "generatedPosts" ||
            listItem.name === "generatedStories")
      );
      return [...generatedByYou, ...generatedPaginationBtn];
    },
    async suggestPostHandler() {
      LogRocket.track("pending_content_generate_post");
      this.isFetchingSuggestedPost = true;
      const { post_id } = await this.createSuggestContent();
      if (post_id) {
        this.suggestedPostsOrStoriesIdsThatLoadedWithSkeleton.push(post_id);

        let newPost = new Post({
          id: post_id,
          isSkeletonLoading: true,
          tooltip: [new PostTooltip(this.defaultTooltip)],
        });
        this.isSuggestedPostWasCreatedOnce = true;
        this.posts = [...[newPost], ...this.posts];
        this.postsLimits.suggested += 1;

        this.isFetchingSuggestedPost = false;
        this.posts.forEach((obj) => {
          if (
            this.suggestedPostsOrStoriesIdsThatLoadedWithSkeleton.includes(
              obj.id
            )
          ) {
            obj.intervalId = setInterval(
              () => this.getSuggestedPosts(obj.id),
              5000
            );
          }
        });
      } else {
        setTimeout(async () => {
          await this.suggestPostHandler();
        }, 15000);
      }
    },
    async getSuggestedPosts(id, isLoadingTime = false) {
      let list = await this.getPost(id);

      if (list.length) {
        let post = list[0];

        this.posts = this.posts.map((obj) => {
          if (obj.id === post.id && obj.intervalId) {
            post.isSkeletonLoading = !post.text || !post.media.length;
            post.intervalId = obj.intervalId;
            if ((post.text && post.media.length) || !!isLoadingTime) {
              clearInterval(obj.intervalId || post.intervalId);
              post.isSkeletonLoading = false;
              this.suggestedPostsOrStoriesIdsThatLoadedWithSkeleton =
                this.suggestedPostsOrStoriesIdsThatLoadedWithSkeleton.filter(
                  (listItem) => listItem !== post.id
                );
              delete post.intervalId;
            }
            return post;
          } else {
            return obj;
          }
        });
      }
    },
    async suggestStoryHandler() {
      LogRocket.track("pending_content_generate_story");
      this.isFetchingSuggestedStory = true;
      const { post_id } = await this.createSuggestContent("story");
      if (post_id) {
        this.suggestedPostsOrStoriesIdsThatLoadedWithSkeleton.push(post_id);

        let newPost = new Post({
          id: post_id,
          isSkeletonLoading: true,
          type: "story",
          tooltip: [new PostTooltip(this.defaultTooltip)],
        });
        this.isSuggestedStoryWasCreatedOnce = true;
        this.stories = [...[newPost], ...this.stories];
        this.storiesLimits.suggested += 1;
        this.isFetchingSuggestedStory = false;

        setTimeout(() => {
          const elem = document.getElementById(`story-${post_id}`);
          this.$scrollTo(elem, { offset: -50 });
        }, 500);

        this.stories.forEach((obj) => {
          if (
            this.suggestedPostsOrStoriesIdsThatLoadedWithSkeleton.includes(
              obj.id
            )
          ) {
            obj.intervalId = setInterval(
              () => this.getSuggestedStories(obj.id),
              5000
            );
          }
        });
      } else {
        setTimeout(async () => {
          await this.suggestStoryHandler();
        }, 15000);
      }
    },

    async getSuggestedStories(id, isLoadingTime = false) {
      let list = await this.getPost(id);

      if (list.length) {
        let story = list[0];

        this.stories = this.stories.map((obj) => {
          if (obj.id === story.id) {
            story.isSkeletonLoading = !story.media.length;
            story.intervalId = obj.intervalId;

            if (story.media.length || isLoadingTime) {
              clearInterval(obj.intervalId);
              this.suggestedPostsOrStoriesIdsThatLoadedWithSkeleton =
                this.suggestedPostsOrStoriesIdsThatLoadedWithSkeleton.filter(
                  (listItem) => listItem !== story.id
                );
              delete story.intervalId;
            }
            return story;
          } else {
            return obj;
          }
        });
      }
    },
    async createSuggestContent(contentType = "post") {
      try {
        return await this.createPostFromRecommendation({
          customerId: this.getCurrentSmbCustomer.id,
          real_time: true,
          task: "generate",
          type: contentType,
        });
      } catch (e) {
        throw e;
      }
    },
    async animatePostHandler(originalPost) {
      LogRocket.track("pending_content_change_to_video");

      console.log(originalPost);

      if (originalPost.type === "post") {
        this.posts.map(async (listItem) => {
          if (listItem.id === originalPost.id) {
            listItem.isSkeletonLoading = true;
          }
          return listItem;
        });
      } else {
        this.stories.map(async (listItem) => {
          if (listItem.id === originalPost.id) {
            listItem.isSkeletonLoading = true;
          }
          return listItem;
        });
      }

      let currentTemplate = originalPost.media.find(
        (i) => i.template !== null
      ).template;

      let paramsForGetTemplateList = {
        calendarItemId: originalPost.calendarItemId,
        customerId: this.getCurrentSmbCustomer.id,
        currentTemplateId: currentTemplate.id,
        contentTemplateId: originalPost.contentTemplateId,
        video_template: true,
      };

      let { template } = await this.getTemplatesInfo(paramsForGetTemplateList);

      const paramsForCreateTemplate = {
        task: "change_template",
        inputParameters: currentTemplate.parameters,
        templateId: template.id,
        customerId: this.getCurrentSmbCustomer.id,
        contentTemplateId: originalPost.contentTemplateId,
        calendarItemId: originalPost.calendarItemId,
      };

      const { template: newTemplate } = await this.createImageFromTemplate(
        paramsForCreateTemplate
      );

      const { changedPost, newTemplateVideo } = await this.approveTemplate(
        newTemplate,
        originalPost,
        currentTemplate
      );

      await this.updateMediaForVideoTemplateHandler(
        changedPost,
        newTemplateVideo
      );
    },
    async approveTemplate(newTemplate, originalPost, currentTemplate) {
      const updatedPost = JSON.parse(JSON.stringify(originalPost));

      if (H.isVideo(newTemplate.preview_url)) {
        await this.videoIsUpload(newTemplate.preview_url);
      }

      const newlyUploadedMedia = await this.uploadObjectMedia({
        mediaUrl: newTemplate.preview_url,
      });

      let editTemplateIndex = originalPost.media.findIndex((item) => {
        if (item.template) {
          return item.template.id === currentTemplate.id;
        }
      });

      let newMedia = {
        cropDetails: updatedPost.media[editTemplateIndex].cropDetails,
        mediaUrl: newlyUploadedMedia.mediaUrl,
        mediaId: newlyUploadedMedia.mediaId,
        ratio: updatedPost.media[editTemplateIndex].ratio,
        template: newTemplate,
        type: H.isVideo(newlyUploadedMedia.mediaUrl) ? "video" : "image",
      };
      updatedPost.media.splice(editTemplateIndex, 1, newMedia);

      const mediaParams = updatedPost.media.map((obj) => ({
        cropDetails: obj.cropDetails,
        mediaId: obj.mediaId,
        mediaUrl: obj.mediaUrl,
        type: H.isVideo(obj.mediaUrl) ? "video" : "image",
        ratio: originalPost.type === "story" ? "vertical" : obj.ratio,
        template: obj.template,
      }));

      await this.updatePosts({
        id: originalPost.id,
        byCustomer: true,
        mediaObject: mediaParams,
      });

      return {
        changedPost: {
          ...{
            ...updatedPost,
            ...{ previousMedia: H.deepCopy(originalPost.media) },
          },
          ...{ isCanRevertVideoTemplate: true },
        },
        newTemplateVideo: newTemplate.preview_url,
      };
    },
    async updateMediaForVideoTemplateHandler(updatedPost) {
      if (updatedPost.type === "post") {
        this.posts = await Promise.all(
          this.posts.map(async (listItem) => {
            if (listItem.id === updatedPost.id) {
              let post = await this.getPost(updatedPost.id);
              post[0].isCanRevertVideoTemplate = true;
              post[0].previousMedia = updatedPost.previousMedia;
              return post[0];
            }
            return listItem;
          })
        );
      } else {
        this.stories = await Promise.all(
          this.stories.map(async (listItem) => {
            if (listItem.id === updatedPost.id) {
              let post = await this.getPost(updatedPost.id);
              post[0].isCanRevertVideoTemplate = true;
              post[0].previousMedia = updatedPost.previousMedia;
              return post[0];
            }
            return listItem;
          })
        );
      }
    },
    async revertAnimateHandler(animatedPost) {
      if (animatedPost.type === "post") {
        this.posts = await Promise.all(
          this.posts.map(async (listItem) => {
            if (listItem.id === animatedPost.id) {
              listItem.isSkeletonLoading = true;
              await this.updatePosts({
                id: animatedPost.id,
                byCustomer: true,
                mediaObject: animatedPost.previousMedia,
              });

              let post = await this.getPost(animatedPost.id);
              post[0].isCanRevertVideoTemplate = false;
              delete post[0].previousMedia;
              return post[0];
            }
            return listItem;
          })
        );
      } else {
        this.stories = await Promise.all(
          this.stories.map(async (listItem) => {
            if (listItem.id === animatedPost.id) {
              listItem.isSkeletonLoading = true;
              await this.updatePosts({
                id: animatedPost.id,
                byCustomer: true,
                mediaObject: animatedPost.previousMedia,
              });

              let post = await this.getPost(animatedPost.id);
              post[0].isCanRevertVideoTemplate = false;
              delete post[0].previousMedia;
              return post[0];
            }
            return listItem;
          })
        );
      }
    },
    async uploadObjectMedia(mediaObject) {
      const data = await this.uploadMediaFromUrl({
        customerId: this.getCurrentSmbCustomer.id,
        inputMediaUrl: mediaObject.mediaUrl,
        isLicensed: mediaObject.isLicensed,
        processMedia: false,
        createMediaItem: true,
        createdBy: "6",
        fileName: "post_raw_file",
        folderName: "None",
      });
      const mediaUrl = data.data["media_url"];
      const mediaId = data.data["media_id"];
      return { mediaUrl, mediaId };
    },
    async videoIsUpload(url) {
      const idPattern = /\/([a-f\d-]+)\.mp4$/i;
      const videoId = url.match(idPattern)[1];
      if (videoId) {
        await this.checkAndProcessVideo(videoId);
      } else {
        return true;
      }
    },
    async checkAndProcessVideo(videoId) {
      let { mediaState } = await this.checkVideoIsUpload(videoId);

      if (!mediaState) {
        await new Promise((resolve) => setTimeout(resolve, 1000));
        return await this.checkAndProcessVideo(videoId);
      }
    },
    redirectCustomerToTheWebsitePaymentPage() {
      const regex = /@atproperties\b/;
      const redirectLink = `https://social-wonder.com/real-estate-self-service/${
        regex.test(this.getCurrentSmbCustomer.mail)
          ? "onboarding-at-properties"
          : "onboarding-choose-package"
      }/?id=${this.getCurrentSmbCustomer.id}&email=${
        this.getCurrentSmbCustomer.mail
      }&phone=${this.getCurrentSmbCustomer.primary_phone}`;
      window.open(redirectLink, "_blank");

      LogRocket.track("pending_approval_upgrade_see_prices");
      this.dialog.isShow = false;
    },
    changeSocialAccountsHandler(currentData) {
      this.currentPost = currentData;
      this.socialPlatforms = H.deepCopy(currentData.postTo);

      this.changeSocialAccounts.isShow = true;
    },
    changeSocialPlatformHandler(socialPlatform) {
      if (this.socialPlatforms.includes(socialPlatform)) {
        this.socialPlatforms = this.socialPlatforms.filter(
          (socialItem) => socialItem !== socialPlatform
        );
      } else {
        this.socialPlatforms.push(socialPlatform);
      }
    },
    async saveSocialPlatformsHandler() {
      try {
        this.isLoading = true;
        await this.actionSmbPosts({
          id: this.currentPost.id,
          postTo: this.socialPlatforms,
        });
        this.posts.find((post) => post.id === this.currentPost.id).postTo =
          this.socialPlatforms;
        this.changeSocialAccounts.isShow = false;
        this.isLoading = false;
      } catch (e) {
        throw e;
      }
    },
  },
  beforeDestroy() {
    clearTimeout(this.polling);
  },
};
</script>

<style scoped lang="scss">
.smb-feedback {
  padding-top: 66px;
  background: #e5e5e5;
  display: flex;
  flex-direction: column;
  @media screen and (min-width: 760px) {
    padding-bottom: 65px;
  }
  overflow-x: hidden;
  flex: 1;
  &__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    .suggest-content-container {
      display: flex;
      justify-content: center;
      margin-top: 20px;
      margin-bottom: 20px;
      .suggest-post-container {
        width: 100%;
        max-width: 107px;
        margin-right: 25px;
        img {
          width: 100%;
        }
        &:hover {
          cursor: pointer;
          .suggest-post-container__img {
            display: none;
          }
          .suggest-post-container__img-hover {
            display: block;
          }
        }
        &__img {
          width: 100%;
        }
        &__text {
          font-size: 10px;
          font-style: normal;
          font-weight: 600;
          line-height: 150%;
          text-align: center;
        }
      }
      .suggest-story-container {
        width: 100%;
        max-width: 90px;
        &__img {
          width: 100%;
        }
        img {
          width: 100%;
        }
        &:hover {
          cursor: pointer;
          .suggest-story-container__img {
            display: none;
          }
          .suggest-story-container__img-hover {
            display: block;
          }
        }
        &__text {
          font-size: 9px;
          font-style: normal;
          font-weight: 500;
          line-height: 150%;
          text-align: center;
        }
      }
    }
    @media screen and (min-width: 760px) {
      flex-direction: row;
      .post-wrapper {
        width: 100%;
      }
      .suggest-content-container {
        flex-direction: column;
        justify-content: unset;
        align-items: center;
        padding-top: 10px;
        margin-right: 15px;
        margin-left: 25px;
        .suggest-post-container {
          margin-right: unset;
          max-width: 113px;
          margin-bottom: 40px;
          margin-top: unset;
          &__text {
            font-size: 11px;
          }
        }
        .suggest-story-container {
          max-width: 113px;
          &__text {
            font-size: 11px;
          }
        }
      }
    }
  }
  @media screen and (min-width: 760px) {
    padding-top: 0;
    padding-bottom: 0;
  }
  &__footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10;
    margin-bottom: 9% !important;
    @media screen and (min-width: 760px) {
      display: none;
    }
  }
  ::v-deep {
    .main-container-stories,
    .main-container {
      padding-right: 0;
      .post-list {
        width: 100%;
        grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
        &__item {
          display: flex;
          flex-direction: column;
          > div {
            display: flex;
            flex: 1;
          }
        }
      }
    }
  }
  &__empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;
    padding-left: 15px;
    padding-right: 15px;
    background: url("../../../../assets/img/congrat-smb-bg.svg") no-repeat
      center bottom;
    background-size: contain;
    &-icon {
      width: 175px;
      height: 175px;
      margin-bottom: 24px;
      img {
        width: 100%;
      }
    }
    &-title {
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 140%;
      margin-bottom: 35px;
    }
    &-description {
      width: 100%;
      max-width: 360px;
      margin: 0 auto;
      text-align: center;
      p {
        font-weight: 400;
        font-size: 14px;
        line-height: 160%;
        text-align: center;
        color: #1f2325;
        opacity: 0.8;
        margin-bottom: 30px;
      }
    }
  }
}
.sort-by-container {
  display: flex;
  align-items: center;
  margin-left: 20px;
  position: absolute;
  right: 30px;
  top: 10px;
  @media screen and (max-width: 1500px) {
    top: 7px;
  }
  @media screen and (max-width: 1200px) {
    margin-top: 5px;
    position: static;
  }
  &__label {
    font-weight: 500;
    font-size: 20px;
    line-height: 140%;
    color: #1d1d1b;
    margin-right: 3px;
    @media screen and (max-width: 1500px) {
      font-size: 18px;
    }
    @media screen and (max-width: 760px) {
      font-size: 16px;
    }
  }
  &__select {
    padding-top: 0;
    margin-top: 0;
    width: auto;
    flex: 0;
    ::v-deep {
      .v-input__slot {
        margin-bottom: 0;
      }
      .v-text-field__details {
        display: none;
      }
      .v-select__selections input {
        display: none !important;
      }
      .v-select__selection {
        font-weight: 500;
        font-size: 20px;
        line-height: 140%;
        color: #70c5b0;
        margin-bottom: 5px;
        text-overflow: inherit;
        overflow: inherit;
        @media screen and (max-width: 1500px) {
          font-size: 18px;
        }
        @media screen and (max-width: 760px) {
          font-size: 16px;
        }
      }
      .v-input__slot:before,
      .v-input__slot:after {
        display: none !important;
      }
      .v-input__append-inner {
        margin-top: 8px;
        @media screen and (max-width: 760px) {
          margin-top: 5px;
        }
      }
      .v-icon {
        color: #70c5b0;
      }
    }
  }
}

.suggest-img-container {
  position: relative;
  display: inline-flex;

  .suggest-story-container__img-hover,
  .suggest-post-container__img-hover {
    display: none;
  }

  .loader {
    position: absolute;
  }
}

::v-deep {
  .reels-introducing {
    &__title {
      font-size: 20px;
      font-weight: 700;
      margin-bottom: 5px;
    }
    &__description {
      margin-bottom: 0;
      a {
        color: #000;
      }
    }
  }
}

.social-networks {
  display: flex;
  margin-top: 10px;
  &__list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    &-item {
      margin-bottom: 20px;
    }
    .custom-checkbox {
      .styled-checkbox + label:before {
        width: 31px;
        height: 31px;
        border: 1px solid #92c4ff;
      }
      .styled-checkbox:checked + label:after {
        font-size: 15px;
        left: 9px;
      }
      .icon {
        display: inline-flex;
        width: 48px;
        height: 48px;
        img {
          width: 100%;
        }
      }
    }
  }
}
</style>
